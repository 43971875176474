import { useState, useEffect } from "react";
import useAuthStore from "../stores/authStore";
import Loader from "../components/Loader";
import MessageAlert from "./MessageAlert";
import { useNavigate } from "react-router";

const LoanSection = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  const [formData, setFormData] = useState({
    amount: null,
    type: "",
    reason: "",
    duration: "",
    email: user?.email,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (user?.kycStatus !== "approved") {
        throw new Error(
          "You can not request for loan without KYC verification."
        );
      } else if (user?.kycStatus !== "pending") {
        throw new Error(
          "KYC verification under review, please be patient while it's being processed"
        );
      }

      const response = await fetch(`https://stanbic-api.bitinverse.com/api/v1/requestloan`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setMessage("Loan requested successfully");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);

      setTimeout(() => {
        navigate("/loanstatus");
      }, 2000);
    } catch (error) {
      setMessage(error.message);
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };


    // Hide message after 5 seconds
    useEffect(() => {
      if (showMessage) {
        const timer = setTimeout(() => {
          setShowMessage(false);
        }, 5000); // Hide message after 5 seconds
  
        return () => clearTimeout(timer); // Cleanup the timer
      }
    }, [showMessage]);

  return (
    <>
      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form class="" onSubmit={handleSubmit}>
              <h3 className="mb-8 dashboard-label">Loan Application</h3>
              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Aomunt ($)
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Type
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"type"}
                  value={formData.type}
                  onChange={handleChange}
                >
                  <option data-code="">--select-- </option>
                  <option value="student" data-country="+93" data-code="AF">
                    Student Loan
                  </option>
                  <option value="Business" data-country="+358" data-code="AX">
                    Business Loan
                  </option>
                  <option value="Individual" data-country="+355" data-code="AL">
                    Individual Loan
                  </option>
                </select>
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Duration
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"duration"}
                  value={formData.duration}
                  onChange={handleChange}
                >
                  <option data-code="">--select-- </option>
                  <option value="1 Week" data-country="+93" data-code="AF">
                    1 Week
                  </option>
                  <option value="2 Week" data-country="+358" data-code="AX">
                    2 Weeks
                  </option>
                  <option value="1 Month" data-country="+355" data-code="AL">
                    1 Month
                  </option>
                  <option value="3 Month" data-country="+355" data-code="AL">
                    3 Month
                  </option>
                  <option value="1 Year" data-country="+355" data-code="AL">
                    1 Year
                  </option>
                </select>
              </div>

              <div className="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Reason
                </label>
                <textarea
                  class="peer h-full shadow-sm produce-input block w-full p-2.5  "
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanSection;
