import { useEffect, useState } from "react";
import avatar from "../assets/profile.png";
import useAuthStore from "../stores/authStore";
import { useNavigate } from "react-router";
import MessageAlert from "./MessageAlert";
import Loader from "./Loader";
import moment from "moment";

const ProfileSection = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const [profileImg, setProfileImg] = useState("");
  const [imgUpdated, setImgUpdated] = useState(false);
  const [imgSize, setImgSize] = useState(0);

  const [isLoading, setIsLoading] = useState(false); // Corrected `islaoding`
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  const [formData, setFormData] = useState([]);

  // -------- Redirect user if not logged in -----------------
  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      setProfileImg(user.profilePicture);
    }
  }, [user, navigate]);

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
    }
    return "";
  };

  // ----------------- Handle update profile ------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...formData, // Spread formData properties
      email: user.email, // Add email property separately
    };

    setIsLoading(true);

    try {
      const response = await fetch(`https://stanbic-api.bitinverse.com/api/v1/updateimage`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setMessage("Successfully Updated!");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // -------------- Handle image upload ----------------------
  const handleAddProfile = (e) => {
    const file = e.target.files[0];

    if (file.size > 2 * 1024 * 1024) {
      setMessage("File size should not exceed 2MB");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the desired dimensions (resize/compress)
        const maxWidth = 300;
        const maxHeight = 300;
        let width = image.width;
        let height = image.height;

        // Maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        setProfileImg(compressedBase64);
        setFormData((prevFormData) => ({
          ...prevFormData,
          profilePicture: compressedBase64,
        }));

        setImgUpdated(true); // Set to true after the image is fully processed
      };
    };
  };

    // Hide message after 5 seconds
    useEffect(() => {
      if (showMessage) {
        const timer = setTimeout(() => {
          setShowMessage(false);
        }, 3000); // Hide message after 5 seconds
  
        return () => clearTimeout(timer); // Cleanup the timer
      }
    }, [showMessage]);

  return (
    <>
      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div className="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form onSubmit={handleSubmit}>
              <div className="avatarWrapper flex justify-center">
                <div
                  className="profileBox"
                  style={{
                    backgroundImage: `url(${profileImg || avatar})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <input
                    type="file"
                    id="profileImage"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleAddProfile}
                    hidden
                  />
                  <label htmlFor="profileImage" className="avatarInputIcon">
                    <i className="b bi-pencil "></i>
                  </label>
                </div>
              </div>

              {/* ------------ Bio -------------- */}
              <h3 className="mb-4 mt-8 dashboard-label">Bio</h3>
              <table className="items-center w-full border-collapse border border-slate-500">
                <tbody>
                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      First Name:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {capitalizeFirstLetter(user?.firstName)}
                    </td>
                  </tr>

                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      Second Name:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {capitalizeFirstLetter(user?.secondName)}
                    </td>
                  </tr>

                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      Birthdate:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {moment(user?.dob).format("LL")}
                    </td>
                  </tr>

                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      Gender:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {capitalizeFirstLetter(user?.gender)}
                    </td>
                  </tr>

                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      Email:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {user?.email}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* ------------ Address -------------- */}
              <h3 className="mb-4 mt-8 dashboard-label">Address</h3>
              <table className="items-center w-full border-collapse border border-slate-500">
                <tbody>
                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      Country:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {capitalizeFirstLetter(user?.country)}
                    </td>
                  </tr>

                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      State:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {capitalizeFirstLetter(user?.state)}
                    </td>
                  </tr>

                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      City:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {capitalizeFirstLetter(user?.city)}
                    </td>
                  </tr>

                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      Zipcode:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {user?.zipCode}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* ------------ System -------------- */}
              <h3 className="mb-4 mt-8 dashboard-label">System</h3>
              <table className="items-center w-full border-collapse border border-slate-500">
                <tbody>
                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      Currency:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      USD
                    </td>
                  </tr>

                  <tr>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      Account:
                    </td>
                    <td className="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {capitalizeFirstLetter(user?.accountType)}
                    </td>
                  </tr>
                </tbody>
              </table>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                className="mt-8 text-white w-full focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : "Update"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSection;
