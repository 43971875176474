import Navbar from "../components/Navbar";
import sidebarImgae from "../assets/sidebar.jpg";
import sideImage2 from "../assets/sidebar2.jpg";
import aboutImg from "../assets/about.jpg";

const AboutUs = () => {
  return (
    <>
      <Navbar />

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            {/* <h2>ABOUT US</h2> */}
            <img src={aboutImg} alt="about" />
            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              Brief History
            </h3>
            <p>
              Optimaholdin was formed in 1872 through the merger of two
              separate banks. These banks had capitalised on the expansion of
              trade between Europe, Asia and Africa. Optimaholdin The
              traditional trade was in cotton from Mumbai, indigo and tea from
              Kolkata, rice from Burma, sugar from Java, tobacco from Sumatra,
              hemp from Manila and silk from Yokohama. The bank played a major
              role in the development of trade with the East following the
              opening of the Suez Canal in 1869 and the extension of the
              telegraph to China in 1871. Optimaholdin was founded in London
              in 1862 from the Cape Colony in South Africa, and started business
              in Port Elizabeth in the following year. The bank was prominent in
              financing the development of the diamond fields of Kimberley from
              the 1870s. It later extended its network further north to the new
              town of Johannesburg when gold was discovered there in 1886. The
              bank expanded in Southern, Central and Eastern Africa and had 600
              offices by 1953.
            </p>

            <p>
              Vision and Mission Vision is to be the best of class financial
              service provider in Turkey through sustained execution of best
              practice, innovation and stakeholder care. Optimaholdin is your
              financial partner, forming a relationship with you based on
              integrity and trust, to provide expert specialist financial and
              investment
            </p>

            <div className="flex flex-col lg:flex-row lg:justify-around">
              <img src={sidebarImgae} alt="morgage" className="mb-4 lg:mb-0" />
              <img src={sideImage2} alt="morgage" />
            </div>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              solutions that help your business and personal wealth grow
            </h3>

            <ul style={{ listStyle: "initial" }}>
              <li className="font-bold">Goals</li>
              <p>
                To maximize value for all our stakeholders (clients, personnel
                and shareholders) by building on Optimaholdin three pillars of
                client delight and care, leveraging its operational and
                technological capabilities and nurturing our staff. Our
                stakeholders value must be consistent, growth oriented and
                accomplished in the spirit of the corporate governance
                framework.
              </p>

              <li className="font-bold">Corporate Values</li>
              <p>
                We consider complete trust and integrity in all our business
                operations as our highest value. Keeping this promise is the
                foundation of our brand.
              </p>

              <li className="font-bold">Commitment</li>
              <p>
                We are committed to creating long term relationships with our
                customers and employees, based on trust, partnership and mutual
                respect.
              </p>

              <li className="font-bold">Progression</li>
              <p>
                We seek progress through innovation and understanding, allowing
                us to better serve our customers needs through every stage of
                life.
              </p>

              <li className="font-bold">Excellence</li>

              <p>
                We aim to set and meet the highest standards of excellence
                through all of our activities, to live up to and deliver on our
                promises, and add value beyond what is expected by our
                customers.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
