import { useEffect, useState } from "react";
import CountryList from "../components/CountryList";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import useAuthStore from "../stores/authStore";
import MessageAlert from "../components/MessageAlert";
import avatar from "../assets/profile.png";

const SignUp = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [profileImg, setProfileImg] = useState("");
  const [imgSize, setImgSize] = useState(0);
  const [imgUpdated, setImgUpdated] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // Corrected `islaoding`
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    gender: "",
    email: "",
    phone: "",
    profilePicture: profileImg,
    dob: "",
    homeAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    accountType: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn, navigate]);

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (isLoggedIn) {
      if (user.role === "admin") {
        navigate("/allusers");
      } else {
        navigate("/dashboard");
      }
    }
  }, [isLoggedIn, user?.role, navigate]);

  // -------------- Handle submit -----------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if image processing is done
    if (!imgUpdated && profileImg) {
      setMessage("Please wait for the profile picture to finish uploading.");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    setIsLoading(true);

    try {
      if (formData.password !== formData.confirmPassword) {
        throw new Error("Passwords do not match!");
      }

      const response = await fetch(
        `https://stanbic-api.bitinverse.com/api/v1/handleverifyemail`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setIsLoading(false);
      const data = { formData, result };

      navigate("/validate", { state: { data } });
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // -------------- Grab image and set the profile image ----------------------
  const handleAddProfile = (e) => {
    const file = e.target.files[0];

    if (file.size > 2 * 1024 * 1024) {
      setMessage("File size should not exceed 2MB");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the desired dimensions (resize/compress)
        const maxWidth = 300;
        const maxHeight = 300;
        let width = image.width;
        let height = image.height;

        // Maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        setProfileImg(compressedBase64);
        setFormData((prevFormData) => ({
          ...prevFormData,
          profilePicture: compressedBase64,
        }));

        setImgUpdated(true); // Set to true after the image is fully processed
      };
    };
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  return (
    <>
      <Navbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form class="" onSubmit={handleSubmit}>
              <h2>REGISTER</h2>
              <h3 className="mb-8 dashboard-label">Personal Information</h3>

              <div className="avatarWrapper flex justify-center">
                <div
                  className="profileBox"
                  style={{
                    backgroundImage: `url('${
                      profileImg ? profileImg : avatar
                    }')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <input
                    type="file"
                    id="profileImage"
                    accept=".jpg, .jpeg, .png"
                    name="profilePicture"
                    onChange={handleAddProfile}
                    hidden
                  />
                  <label
                    htmlFor="profileImage"
                    className="avatarInputIcon"
                    style={{ padding: "1px 10px" }}
                  >
                    <i className="b bi-pencil "></i>
                  </label>
                </div>
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter the of crop"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Second Name
                </label>
                <input
                  type="text"
                  id="price"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter the price"
                  name="secondName"
                  value={formData.secondName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Gender
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"gender"}
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="I prefer not to say">
                    I prefer not to say
                  </option>
                </select>
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* -------------------- Address Information ------------------------ */}
              <h3 className="mb-8 dashboard-label">Address Information</h3>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Home Address
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="homeAddress"
                  value={formData.homeAddress}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  City Name
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  State
                </label>
                <input
                  type="text"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Zipcode
                </label>
                <input
                  type="number"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Country of Residence
                </label>
                <CountryList
                  data={{ formData, setFormData, name: "country" }}
                />
              </div>

              {/* -------------------- Account Information ------------------------ */}
              <h3 className="mb-8 dashboard-label">Account Information</h3>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Type
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"accountType"}
                  value={formData.accountType}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  <option value="Savings Account">Savings Account</option>
                  <option value="Chequing Account">Chequing Account</option>
                  <option value="Fixed Deposit Account">
                    Fixed Deposit Account
                  </option>
                </select>
              </div>
              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Password
                </label>
                <input
                  type="password"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="availability"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter availability"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={isLoading || (!imgUpdated && profileImg)} // Disable if loading or image is not ready
              >
                {isLoading ? <Loader /> : "Create Account"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
