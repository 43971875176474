import React, { useEffect, useState } from "react";
import Template from "../components/Template";
import TradingViewWidget from "../components/TradingViewWidget";
import moment from "moment";
import useAuthStore from "../stores/authStore";
import { useNavigate } from "react-router";
import useTransactionStore from "../stores/transactionStore";

const Dashboard = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const user = useAuthStore((state) => state.user);
  const showBalance = useAuthStore((state) => state.showBalance);
  const handleShowBalance = useAuthStore((state) => state.handleShowBalance);
  const transactions = useTransactionStore((state) => state.transactions);

  

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
    }
  };

  const handleHideBalance = () => {
    handleShowBalance(!showBalance);
  };

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, []);

  // const { firstName, secondName, accountNumber, totalBalance } = user;

  return (
    <>
      <Template />

      <div class="p-4 sm:ml-64 dashboard-section">
        <TradingViewWidget />

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8 mt-10">
          {/* ================ First Layer ============================== */}
          <div class="rounded h-70 chart-card-wrapper" id="total-balance">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Your Balance</h3>

              {/* {showBalance ? (
                <li
                  className="bi bi-eye-slash eye-drop"
                  onClick={handleHideBalance}
                ></li>
              ) : (
                <li
                  className="bi bi-eye eye-drop"
                  onClick={handleHideBalance}
                ></li>
              )} */}
            </div>
            <br />
            <br />
            <span id="balance">
              {" "}
              {/* {showBalance
                ? "$****"
                : `$${user?.totalBalance.toLocaleString()}`} */}
              {`$${Number(user?.totalBalance).toLocaleString()}`}
            </span>
            <br />
            <br />
            <span> {`Last updated ${moment().calendar()}`}</span>
          </div>

          <div class="rounded h-70 chart-card-wrapper" id="account-details">
            <h4>Receive Money</h4>
            <br />
            <br />
            <span className="account-info">Account Name:</span>
            <span className="account-info" style={{ color: "#e8fb92" }}>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              {`${capitalizeFirstLetter(
                user?.firstName
              )} ${capitalizeFirstLetter(user?.secondName)}`}
            </span>
            <br />
            <br />
            <span className="account-info">Account Number:</span>{" "}
            <span className="account-info" style={{ color: "#e8fb92" }}>
              &nbsp; &nbsp; &nbsp; {user?.accountNumber}
            </span>
          </div>
        </div>

        {/* ============== Forex Section ======================== */}
        <h3 className=" mb-2 dashboard-label">Current Trading Indices</h3>
        <iframe
          title="TradingView Widget"
          src="https://www.tradingview-widget.com/embed-widget/forex-heat-map/?locale=en#%7B%22width%22%3A%22100%25%22%2C%22height%22%3A200%2C%22currencies%22%3A%5B%22EUR%22%2C%22USD%22%2C%22GBP%22%2C%22CAD%22%2C%22CNY%22%5D%2C%22isTransparent%22%3Afalse%2C%22colorTheme%22%3A%22light%22%2C%22utm_source%22%3A%22demoscript.scriptsdemo.website%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22forex-heat-map%22%2C%22page-uri%22%3A%22demoscript.scriptsdemo.website%2Falpha%2Falpha%2Fdashboard%22%7D"
          width="100%"
          height="300"
          frameBorder="0"
        />

        {/* ============== Transaction Section ======================== */}
        <h3 className=" mb-2 mt-8 dashboard-label">Transaction History</h3>

        <div class="block w-full overflow-x-auto">
          <table class="items-center  w-full border-collapse ">
            <thead>
              <tr>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Account
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Amount
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Type
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Date
                </th>
              </tr>
            </thead>

            <tbody>
              {transactions?.slice(0, 6)
                ?.map(({ accountNumber, amount, type, date }) => (
                  <React.Fragment>
                    <tr>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                        {accountNumber}
                      </td>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                        ${amount.toLocaleString()}
                      </td>
                      <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <span
                          className="badge"
                          id={`${type === "debit" ? "danger" : "approved"}`}
                        >
                          {type}
                        </span>
                      </td>
                      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i class="fas fa-arrow-up text-emerald-500 mr-4"></i>

                        {/* {moment.utc(updatedAt.split("T")[0]).format("ll")} */}
                        {moment(date).format("LL")}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
