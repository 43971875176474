"use client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import AdminTemplate from "./AdminTemplate";
import moment from "moment";
import { Button, Modal } from "flowbite-react";
import Loader from "./Loader";

const UpdateTransaction = () => {
  const [openModal, setOpenModal] = useState(false);

  const location = useLocation();
  const user = location.state?.user;
  const [transactions, setTransactions] = useState([]);
  const [referenceId, setReferenceId] = useState("");
  const userEmail = user?.email;
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    amount: "",
    date: "",
    type: "",
    email: userEmail,
  });

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Get Transactions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await (
          await fetch(`https://stanbic-api.bitinverse.com/api/v1/gettransactions`, {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ email: userEmail }),
          })
        ).json();

        setTransactions(result);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpen = (transaction) => {
    setOpenModal(true);
    setReferenceId(transaction.referenceId);
  };

  const handleClose = () => {
    setOpenModal(false);
    setFormData({
      amount: "",
      date: "",
      type: "",
      email: userEmail,
    });
  };

  // UPDATE TRANSACTION
  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = { ...formData, referenceId: referenceId };
    setIsLoading(true);

    try {
      const result = await (
        await fetch(`https://stanbic-api.bitinverse.com/api/v1/updatedate`, {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
      ).json();

      setTransactions(result);

      setFormData({
        amount: "",
        date: "",
        type: "",
        email: userEmail,
      });
      setIsLoading(false);
      setOpenModal(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <AdminTemplate />

      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Body>
          <h4 style={{ color: "gray" }}>Edit Transaction</h4>
          <div className="space-y-6">
            <form onSubmit={handleUpdate}>
              <div class="mb-4 mt-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Amount
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                />
              </div>

              <div class="mb-4 mt-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Date
                </label>
                <input
                  type="date"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Type
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  name={"type"}
                  value={formData.type}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  <option value="credit">Credit</option>
                  <option value="debit">Debit</option>
                </select>
              </div>

              <Modal.Footer>
                <Button type="submit">
                  {" "}
                  {isLoading ? <Loader /> : "Proceed"}
                </Button>
                <Button color="gray" onClick={() => handleClose()}>
                  Cancel
                </Button>
              </Modal.Footer>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <div class="p-4 sm:ml-64 dashboard-section">
        {/* ============== Transaction Section ======================== */}
        <h3 className=" mb-2 mt-8 dashboard-label">
          {`${capitalizeFirstLetter(user?.firstName)}  ${capitalizeFirstLetter(
            user?.secondName
          )}`}{" "}
          History{" "}
        </h3>

        <div class="block w-full overflow-x-auto">
          <table class="items-center  w-full border-collapse ">
            <thead>
              <tr>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  S/N
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Amount
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Date
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Transaction ID
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Type
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Edit
                </th>
              </tr>
            </thead>

            <tbody>
              {transactions?.map((transaction, index) => (
                <React.Fragment>
                  <tr>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                      {index + 1}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                      ${transaction?.amount.toLocaleString()}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                      {moment(transaction?.date).format("LLL")}
                    </td>
                    <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {transaction?.referenceId}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <span
                        className="badge"
                        id={`${
                          transaction?.type === "debit" ? "danger" : "approved"
                        }`}
                      >
                        {transaction?.type}
                      </span>
                    </td>
                    <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <Button onClick={() => handleOpen(transaction)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UpdateTransaction;
