import partners from "../assets/alliance.jpg";
import img1 from "../assets/img1.webp";
import img2 from "../assets/img2.webp";
import img3 from "../assets/img3.webp";

const MissionSection = () => {
  return (
    <>
      <div class="mr-8 sm:m-20 md:mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class=" mission-section-wrapper col-span-1  p-4">
          <h3 id="mission-title">About Us</h3>
          <p>
            Optimaholdin Bank's history began in 1866 when two American banks
            merged to capitalize on the growing trade between Europe, Asia, and
            the Americas. The bank quickly became a significant player in
            facilitating trade, handling commodities like cotton, tea, rice,
            sugar, tobacco, hemp, and silk. Its influence expanded further
            following the opening of the Suez Canal in 1869 and the extension of
            the telegraph to China in 1871, which significantly boosted trade
            efficiency. Originally founded in London in 1862, Optimaholdin
            established its first American office in 1863, enhancing its
            transatlantic connections. By 1953, Optimaholdin had evolved into
            a major international bank with over 600 branches, solidifying its
            position in global trade and finance.
          </p>
        </div>

        <div class="col-span-1  p-4  no-top">
          <div class="grid grid-cols-1 mb-4 gap-4">
            <div class="col-span-1  p-4">
              <img src={img3} alt="img" className="rounded-xl" />
            </div>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <div class="col-span-1  p-4">
              <img
                src={img2}
                alt="img"
                className="rounded-xl"
                id="top-sm-img"
              />
            </div>
            <div class="col-span-1  p-4">
              <img
                src={img1}
                alt="img"
                className="rounded-xl"
                id="bottom-sm-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="partners p-4">
        <p style={{ color: "#216206", fontSize: "20px" }}>
          Optimaholdin is proud to be a member of these organizations:
        </p>
        <img src={partners} alt="partners" />
      </div>
    </>
  );
};

export default MissionSection;
