import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router";
import MessageAlert from "../components/MessageAlert";

const EmailRecover = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [email, setEmail] = useState("");

  const backLogin = () => {
    navigate("/login");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        `https://stanbic-api.bitinverse.com/api/v1/handleverifyemail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Something went wrong");
      }

      setMessage("Code resent");
      setMessageID("successAlert");
      setShowMessage(true);

      setTimeout(() => {
        navigate("/changepassword", { state: { result } });
      }, 4000);
    } catch (error) {
      console.error("Error:", error);
      setMessage(error.message || "Something went wrong");
      setMessageID("declineAlert");
      setShowMessage(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  return (
    <>
      <Navbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form class="" onSubmit={handleSubmit}>
              <h2>Forgot your password</h2>
              <div class="mb-4 mt-8">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Enter email address
                </label>
                <input
                  type="email"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <button
                style={{ backgroundColor: "#216206", marginBottom: "30px" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Proceed"}
              </button>

              <span
                onClick={backLogin}
                style={{ color: "orangered", cursor: "pointer" }}
              >
                Back to login
              </span>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailRecover;
